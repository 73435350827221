<template>
  <div class="download">
    <b-container class="mt-4">
      <h1 class="my-4" v-if="page">{{ page.title }}</h1>
      <div class="collapse-group">
        <div
          v-for="(cards, index) in content"
          :key="`cards-${cards.title}`"
          :aria-controls="'collapse_' + index"
          aria-expanded="false"
          class="card my-4 px-md-4 px-2"
          data-toggle="collapse"
          role="button"
          v-show="isEmpty(cards.downloads)"
        >
          <div class="title my-0 p-3" role="tab">{{ cards.title }}</div>
          <div class="collapse p-3" :id="'collapse_' + index">
            <div class="collapse-content">
              <ul class="mx-0 px-0">
                <!-- <li class="btn btn-round text-center"
                    v-for="downloads in cards.downloads"
                    :key="`downloads-${downloads.title}`"
                    v-show="downloads.url != null || downloads.file != null">
                  <a rel="" :href="downloads.url != null ? downloads.url : downloads.file">{{ downloads.title }}<i class="pdf1"></i></a>
                </li> -->
                <li
                  v-for="download in cards.downloads"
                  :key="`downloads-${download.title}`"
                  :style="{ display: download.downloadFile.length > 0 ? 'block' : 'inline' }"
                >
                  <template v-if="download.downloadFile.length > 0">
                    <span class="sub-title blue">{{ download.title }}</span>
                    <ul class="mx-0 px-0">
                      <li
                        v-for="fileItem in download.downloadFile"
                        :key="`downloadsFile-${fileItem.title}`"
                        style="display: inline-block"
                      >
                        <a
                          class="btn btn-round text-center"
                          v-if="fileItem.file != null"
                          :href="fileItem.file"
                          :class="[fileItem.subTitle != null ? 'hasSubTitle' : '']"
                          target="_blank"
                        >
                          <b-row>
                            {{ fileItem.title }}
                            <i class="pdf1" :title="fileItem.title"></i>
                          </b-row>
                          <b-row class="btn-subtitle" v-if="fileItem.subTitle != null">{{ fileItem.subTitle }}</b-row>
                        </a>
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    <a
                      :class="[download.subTitle != null ? 'hasSubTitle' : '']"
                      class="btn btn-round"
                      style="text-align: left"
                      v-if="download.url != null || download.file != null"
                      :href="download.url != null ? download.url : download.file"
                      target="_blank"
                    >
                      <b-row>
                        {{ download.title }}
                        <i class="pdf1" :title="download.title"></i>
                      </b-row>
                      <b-row class="btn-subtitle" v-if="download.subTitle != null">{{ download.subTitle }}</b-row>
                    </a>
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import $ from 'jquery'
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {},
  mixins: [Lifecycle],
  data() {
    return {
      content: null
    }
  },
  computed: {
    ...mapState('page', ['statementslist']),
    ...mapState('common', ['page']),
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  watch: {},
  methods: {
    langName(name) {
      return name[lanToISO639(this.$route.params.lang)]
    },
    isEmpty(downloads) {
      let flag = false
      if (downloads.length > 0) {
        return true
      } else {
        downloads.forEach(item => {
          if (item.file != null || item.url != null) {
            return true
          } else {
            if (item.downloadFile.length > 0) {
              item.forEach(fileItem => {
                if (fileItem.file != null) {
                  return true
                }
              })
            }
          }
        })
      }
      return flag
    },
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_STATEMENTS_LIST', {
            preview: preview,
            id: id,
            vid: vid
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {
      this.content = this.statementslist
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  mounted() {
    this.fetchData()
    this.$nextTick(() => {
      $(this.$el).on('click', '.collapse-group .card .title', function(e) {
        $(this)
          .parent('.card')
          .toggleClass('collapsed')
        $(this)
          .parent('.card')
          .find('.collapse')
          .toggle()
      })
    })
  },
  head() {
    return {
      title: '財務報表 - 大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
